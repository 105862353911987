<template>
  <div class="message-success-order">
    <back />
    <div class="img">
      <div class="success">
        <img :src="success" alt="">
      </div>
    </div>
    <div class="container">
      <div class="title">Поздравляем!<br>Ваш заказ оформлен</div>
      <div class="sub">Ваш заказ размещён в разделе “Мои заказы” благодарим за покупку Green Go</div>

      <div class="buttons">
        <n-link :to="{name:'products.list'}">
          <n-button wide color="primary">Продолжить покупки</n-button>
        </n-link>
        <n-link :to="{name:'orders.index', params: {id: orderId}}">
          <n-button wide>Просмотреть заказ</n-button>
        </n-link>
      </div>
    </div>
  </div>
</template>

<script>
import success from 'assets/success.png'

export default {
  name: 'MessageSuccessOrder',
  data() {
    return {
      success,
    }
  },
  computed: {
    orderId() {
      return this.$route.params.id
    },
  },
}
</script>

<style lang="scss" scoped>
.message-success-order {
  .img{
    height: 210px;
    width: 210px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid #B7E0A7;
    border-radius: 50%;
    margin: 52px auto 20px;
  }

  .title{
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: #000;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
  }

  .sub{
    font-size: 14px;
    text-align: center;
    color: #808080;
    margin-bottom: 57px;
    position: relative;
    z-index: 2;
  }

  .buttons {
    .n-button {
      margin-top: 10px;
    }
  }
}
</style>
